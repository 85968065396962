'use strict';

const $ = window.jQuery;
const Toastr = window.Toastr;
const Util = window.Util;
const App = window.App;
const SWAL = window.SWAL;

class Table {
	constructor(element) {
		this.element = element.hasClass('table-content') ? element : undefined;
		this.ajax = undefined;
		if (!this.element) return {
			'error': 'Table init while no table element found'
		};

		this.count = $('select[name=count]', this.element).length === 1 ? $('select[name=count]', this.element).eq(0) : undefined;
		this.form = $('form.filters', this.element).length === 1 ? $('form.filters', this.element).eq(0) : undefined;
		this.table_parent = $('.table-parent', this.element).length === 1 ? $('.table-parent', this.element).eq(0) : undefined;
		this.table = $('table', this.element).length === 1 ? $('table', this.element).eq(0) : undefined;
		this.caption = $('.caption', this.table_parent).length === 1 ? $('.caption', this.table_parent).eq(0) : undefined;
		this.url = $('.table-content').data('url');
		this.loaded = this.element.data('loaded') || false;

		this.element.on('update', event => {
			event.stopPropagation();
			this.update();
		});

		if (!this.loaded || !$(this.element).data('loaded')) {
			if (this.checkbox_init() && this.count_init() && this.paging_init() && this.sort_init() && this.search_init()) {
				$(this.element).data('loaded', true);
				this.loaded = true;
				this.element.trigger('update');
			} else {
				return {
					'error': 'Table initialization failed'
				};
			}
		} else {
			Toastr.warning('Table already loaded');
		}

		this.fixHeight();
		return this;
	}

	static paging_element(page) {
		return `<li class="page-item" data-value="${page}"><a class="page-link" href="javascript:">${page}</a></li>`;
	}

	serializeForm() {
		let params = {};
		if (this.form) {
			$('.filtergroup', this.form).each(function () {
				if ($(this).find('input.date-range').length) {
					if ($(this).find('input.date-range.start').val() || $(this).find('input.date-range.end').val()) {
						params[$(this).find('input.date-range').attr('name')] = $(this).find('input.date-range.start').val() + ' - ' + $(this).find('input.date-range.end').val();
					}
				} else if ($(this).find('input.filter_number').length) {
					if ($(this).find('input.filter_number.start').val() || $(this).find('input.filter_number.end').val()) {
						let start = $(this).find('input.filter_number.start').val();
						let end = $(this).find('input.filter_number.end').val();
						if (start === 'undefined') start = '';
						if (end === 'undefined') end = '';
						params[$(this).find('input.filter_number').attr('name')] = start + ' - ' + end;
					}
				} else if ($(this).find('input:checked').length) {
					params[$(this).find('input:checked').attr('name')] = $(this).find('input:checked').val();
				} else if ($(this).find('.select2').length && $(this).find('.select2').val()) {
					params[$(this).find('.select2').attr('name')] = $(this).find('.select2').val();
				} else if ($(this).find('input[type!=checkbox][type!=radio][type!=search]:not(.daterange)').length) {
					params[$(this).find('input[type!=checkbox][type!=radio][type!=search]:not(.daterange)').attr('name')] = $(this).find('input[type!=checkbox][type!=radio][type!=search]:not(.daterange)').val();
				}
			});
			if (this.form.find('input[name=search_context]').length && this.form.find('input[name=search_context]').val()) {
				params.search_context = this.form.find('input[name=search_context]').val();
			}
		}
		return params;
	}

	update(page, parameters) {
		const proto = this;
		App.blockUI({
			boxed: true,
			target: this.table_parent,
			message: 'Загрузка...'
		});
		page = page ? page : 1;
		const offset = (page - 1) * (this.count ? this.count.val() : 0) || 0;

		let sort;

		$('td[data-id]').each(function () {
			if ($(this).data('sort')) {
				sort = sort ? `${sort},${$(this).data('id')}:${$(this).data('sort')}` : `${$(this).data('id')}:${$(this).data('sort')}`;
			}
		});

		App.blockUI({
			boxed: true,
			target: this.element,
			message: 'Загрузка...'
		});

		const queryParams = (function getParametersFromQuery() {
			let queryParams = decodeURIComponent( window.location.search ) // ["id=123", "search=256"]
				.replace('?', '')
				.split('&');

			queryParams = queryParams.reduce( (acc, string) => {
				acc[string.replace(/=.*/, '')] = string.replace(/^.+?=/, '');
				return acc;
			}, {});

			return queryParams;
		})();

		this.ajax = $.ajax({
      url: window.location.pathname
        .replace('/card/', '/docs/')
        .replace('/edit/', '/'),
			type: 'post',
			data: Util.serialize(this.element.data('post'), this.serializeForm(), {
				offset: offset
			}, {
				count: this.count ? this.count.val() : null
			}, {
				sort: sort
			},  parameters, queryParams),
			success: function (data) {
				proto.table_parent.html(data).promise().done(function () {
					proto.table = $('table', proto.element);
					proto.paging_set(page);
					proto.sort_init();
					proto.expandable_init();
					if ( !Util.isIE() ){ proto.fixHeader(); }
					proto.fixHeight();
					proto.table_parent.plugin();
					proto.table_parent.unibutton().initAll();
					if ($('.count_result') && typeof (proto.table.data('results')) !== undefined) {
						$('.count_result').text(proto.table.data('results'));
					}
					selectedRow();
					App.unblockUI(proto.element);
				});
			},
			error: function () {
				SWAL.error('Произошла ошибка, таблица не была обновлена');
				App.unblockUI(this.element);
			}
		});

		function selectedRow () {
			if (sidepanel && document.querySelector('.panel_refresh')){
				const post = document.querySelector('.panel_refresh').dataset.post;
				if (post) {
					$('.table-content td [data-post*="' + JSON.parse(post).did + '"]').closest('tr').addClass('selected');
				}
			}
		}

		App.unblockUI(this.table_parent);

		return true;
	}

	checkbox_init() {
		$('input[type=checkbox]', this.element).on('click', function () {
			const input = $(this);
			const check = input.prop('checked') ? 1 : 0;
			$.ajax({
				url: Util.blackbox('/app/dynproc/'),
				type: 'post',
				data: $.param(Util.serialize(input.data(), {
					checked: check
				})),
				success: function (data) {
					if (!data.status) {
						input.prop('checked', !check);
						SWAL.error(data);
					}
				}
			});
		});
		return true;
	}

	paging_init() {
		if (this.element.data('paging')) {
			const proto = this;
			for (let i = 0; i < $('.pagination', this.element).length; i++) {
				let pager = $($('.pagination', this.element)[i]);
				if (!pager.data('paging_init')) {
					for (let li_dom of $('li', pager)) {
						const li = $(li_dom);
						li.on('click', function () {
							const ul = li.parent();
							if (li.data('value')) {
								ul.find('li.active').removeClass('active');
								li.addClass('active');
								proto.update(li.data('value'));
							} else if (li.data('action')) {
								const curval = ul.find('li.active').data('value');
								const action = li.data('action');
								const nextval = curval + action;
								if (ul.find('li[data-value=' + nextval + ']').length) {
									ul.find('li.active').removeClass('active');
									ul.find('li[data-value=' + nextval + ']').addClass('active');
									proto.update(nextval);
								}
							}
						});
					}
					pager.data('paging_init', true);
				}
			}
		}
		return true;
	}

	paging_set(page) {
		if (this.element.data('paging')) {
		// console.log($('.show_results_paging', this.element));
			const proto = this;
			const count = this.count ? Number(this.count.val()) : 10;
			const results = Number(this.table.data('results'));
			const hide_result = Boolean(this.table.data('hide_result'));
			console.log(hide_result);
			const total_pages = hide_result ? 0 : Math.ceil(results / count);
			const paging_width = 1;
			const minpage = (page - paging_width < 1) ? 1 : page - paging_width;
			const maxpage = ((page + paging_width > results / count) && !hide_result ) ? Math.ceil((results / count)) : page + paging_width;
			const dots = '<li class="page-item dots"><a class="page-link px-1" href="javascript:">...</a></li>';
			console.log(maxpage);
			for (let i = 0; i < $('.pagination', this.element).length; i++) {
				const paginator_ul = $($('.pagination', this.element)[i]);

				$('li[data-value]', paginator_ul).remove();
				$('li.dots', paginator_ul).remove();

				!hide_result && results <= count ? paginator_ul.hide() : paginator_ul.show();

				for (let i = minpage; i <= maxpage; i++) {
					paginator_ul.find('li[data-action=1]').before(Table.paging_element(i));
				}

				if (page === total_pages - paging_width - 1) {
					paginator_ul.find('li[data-action=1]').before(Table.paging_element(total_pages));
				} else if (page === total_pages - paging_width - 2) {
					paginator_ul.find('li[data-action=1]').before(Table.paging_element(total_pages - 1) + Table.paging_element(total_pages));
				} else if (page < total_pages - paging_width - 1) {
					paginator_ul.find('li[data-action=1]').before(dots + Table.paging_element(total_pages));
				}

				if (page === paging_width + 2) {
					paginator_ul.find('li[data-action=-1]').after(Table.paging_element(1));
				} else if (page === paging_width + 3) {
					paginator_ul.find('li[data-action=-1]').after(Table.paging_element(1) + Table.paging_element(2));
				} else if (page > paging_width + 2) {
					paginator_ul.find('li[data-action=-1]').after(Table.paging_element(1) + dots);
				}

				paginator_ul.find('li[data-value=' + page + ']').addClass('active');

				$('li', paginator_ul).each(function () {
					const li = $(this);
					li.on('click', function () {
						if (li.data('value')) {
							paginator_ul.find('li.active').removeClass('active');
							li.addClass('active');
							proto.update(li.data('value'));
						}
					});
				});
			}
		}
		return true;
	}
	count_select_init() {
		let regexp = new RegExp('app.*/');
		const countSelect = $('[name="count"]');
		countSelect.on('change', (e) => {
			localStorage[regexp.exec(e.currentTarget.baseURI)] = $(':checked', e.currentTarget)[0].value;
		});
		let currentStorage = localStorage[regexp.exec(window.location.href)];
		let curValues = $('[name=count] option').map((e, i) => i.value);
		if (currentStorage && $.inArray(currentStorage, curValues) !== -1) {
			countSelect[0].value = localStorage[regexp.exec(window.location.href)];
		}
		if (!$('[name=count] option').map((e, i) => i.value).length){
			$(countSelect.hide());
		}
	}
	count_init() {
		this.count_select_init();
		if (this.element.data('paging')) {
			if (this.count && this.count.length) {
				this.count.on('change', () => {
					this.element.trigger('update');
				});
			}
		}
		return true;
	}

	sort_init() {
		const proto = this;
		const td = $('thead td[data-id]', this.table);
		td.css('cursor', 'pointer');
		td.on('click', function () {
			const clicked_td = $(this);
			clicked_td.data('current', 1);
			td.each(function () {
				if (!$(this).data('current')) {
					$(this).data('sort', null);
				}
			});
			if (clicked_td.data('sort') === 'asc') {
				clicked_td.data('sort', 'desc');
			} else {
				clicked_td.data('sort', 'asc');
			}
			proto.element.trigger('update');
		});
		return true;
	}

	search_init() {
		if (!this.element.data('ajax')) {
			const proto = this;
			$('.searchBtn', this.element).parent().css('display', 'none');
			$('.searchBtn', this.element).parent().parent().css('width', '100%');
			$('input.form-control', this.element).on('keypress', function (e) {
				if (e.key === "Enter") {
					e.preventDefault();
					e.stopPropagation();
				}
			});
			$('input.form-control', this.element).on('textInput keyDown input change', function () {
				const val = $(this).val();

				const contains = (selector, text, baseElement) => {
					if (!baseElement) baseElement = document;
					var elements = baseElement.querySelectorAll(selector);
					return [].filter.call(elements, function(element){
					  return RegExp(text).test(element.textContent);
					});
				};

				if (val.length) {
					// const split = val.split(/\s+/);
					const regexp = new RegExp (val, 'i');

					$('tbody tr', proto.table).css({ 'display': 'none' });
					contains('tbody tr', regexp, proto.table[0]).forEach( div => div.style.display = 'table-row');
					// $(`tbody tr:contains("${val}")`, proto.table).css({ 'display': 'table-row' });
					// $(`tbody tr:not(:contains("${val}"))`, proto.table).css({ 'display': 'none' });

					// for (let tr of $('tbody tr', proto.table)) {
					// 	let match = Util.find(val, tr, 'div, a, span');
					// 	if (match.length) {
					// 		$(tr).css({
					// 			'display': 'table-row'
					// 		});
					// 		for (let str of match) {
					// 			$(str).css({
					// 				'font-weight': 'bold'
					// 			});
					// 		}
					// 	} else {
					// 		$(tr).css({
					// 			'display': 'none'
					// 		});
					// 		$('div, a, span', tr).css({
					// 			'font-weight': 'normal'
					// 		});
					// 	}
					// }
				} else {
					$((`tbody tr`), proto.table).css({ 'display': 'table-row' });
					// for (let tr of $('tbody tr', proto.table)) {
					// 	$(tr).css({
					// 		'display': 'table-row'
					// 	});
					// 	$('div, a, span', tr).css({
					// 		'font-weight': 'normal'
					// 	});
					// }
				}
			});
		}
		return true;
	}



	expandable_init() {
		const proto = this;
		$('tr.expandable', this.table).on('click', function () {
			const clicked_row = $(this);
			if (clicked_row.data('expanded') !== true) {
				proto.table.find('tr[data-parent=' + clicked_row.data('id') + ']').each(function () {
					$(this).css({
						'visibility': 'visible',
						'opacity': 1
					});
				});
				clicked_row.data('expanded', true);

			} else {
				proto.hide_rows(clicked_row.data('id'));
			}
		});
		return true;
	}

	hide_rows(id) {
		const proto = this;
		const row = $('tr[data-id=' + id + ']');
		proto.table.find('tr[data-parent=' + id + ']').each(function () {
			const hiding_row = $(this);
			const child_rows = $('tr[data-parent=' + hiding_row.data('id') + ']');
			if (child_rows.length) {
				proto.hide_rows(proto.table, hiding_row.data('id'));
			}
			hiding_row.css({
				'visibility': 'collapse',
				'opacity': 0
			});
		});
		row.data('expanded', false);
		return true;
	}

	fixHeader() {
		if (this.table.hasClass('fixheader')){
			new DynamicHeader(this.table.get(0));
		}
	}

	fixHeight() {
		if ( !document.querySelector('.table_row_fixed', this.element ) ) return;
		const control_height = $('.control_height').get();
		for (let i = 0; i < control_height.length; i++) {
			let cur_height = control_height[i].scrollHeight;
			let max_height = parseInt($(control_height[i]).css('max-height'));

			if (cur_height > max_height) {
				$(control_height[i]).css('overflow-y', 'hidden');
				$(control_height[i]).closest('td').find('.control_height').append('<div class="bottom_fade"></div>');

				$(control_height[i]).on('click', function (event) {

					const controller_near = $(this).parent().children('.control_height');
					const controllers = controller_near.closest('tr').find('.control_height');
					const buttons = controller_near.closest('tr').find('.btn_fix');

					event.stopPropagation();
					event.stopImmediatePropagation();
					if (controller_near.hasClass('fix_height_on')) {
						controllers.removeClass('fix_height_on');
						controllers.addClass('fix_height_off');
						$(this).closest('tr').find('.bottom_fade').show();
						buttons.html('Развернуть <i class="fa fa-angle-down"></i>');
					} else {
						controllers.addClass('fix_height_on');
						controllers.removeClass('fix_height_off');
						$(this).closest('tr').find('.bottom_fade').hide();
						buttons.html('Свернуть <i class="fa fa-angle-up">');
					}
				});
			}
		}
	}
}


$.fn.table = function () {
	const table = new Table(this);
	if (table.error) {
		Toastr.error(table.error, 'Table initialization error');
		throw new Error(table.error);
	}
	return table;
};

class DynamicHeader {
	constructor(table) {
		this.table = table;
		this.header = table.querySelector('thead');
		this.body = table.querySelector('tbody');
		this.init();
	}
	init() {
		const isFirefox = /firefox/i.test(navigator.userAgent);
		const transformStyle = isFirefox ? 'flat' : 'preserve-3d';
		const {table, header, body} = this;

		if (!table || !header || !body) { return; }

		table.style.borderCollapse = 'separate';
		table.style.borderSpacing = 0;
		table.style.transformStyle = transformStyle;

		header.style.zIndex = 1;
		body.style.transform = 'translate3d(0,0,0)';

		window.addEventListener("scroll", (e) => this.fix(table, header) );
	}

	fix(table, head) {
		const topOffset = 49;

		if (!table || !head) { return; }

		const tableTopOffset =
		  window.scrollY + table.getBoundingClientRect().top;

		if (window.scrollY > tableTopOffset - topOffset) {
		  head.style.transform = `translate3d(0, ${window.scrollY -
			tableTopOffset +
			topOffset}px, 1px)`;
		} else {
		  head.style.transform = "none";
		}
	}
}